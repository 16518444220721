body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #dadada !important;
}

h1,
h3 {
  font-weight: bold;
  color: #213056;
  margin-bottom: 0;
}

.cards-info {
  display: flex;
  justify-content: center;
  margin: 20px auto 0 auto;
  padding-top: 45px;
  position: relative;
  flex-wrap: wrap;
}

.wrapper {
  position: relative;
  clear: both;
  margin: 0 auto 0 auto;
  width: 100%;
  overflow: hidden;
  padding: 20px;
  border-radius: 10px;
  
}


.wrapper-card{
  position: relative;
  clear:both;
  margin: 0 auto 0 auto;
  width: 100%;
  overflow: hidden;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
}

.wrapper-card {
  position: relative;
  clear: both;
  margin: 0 auto 0 auto;
  width: 100%;
  overflow: hidden;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
}

.content-wrapper {
  display: grid;
}

.content {
  width: auto;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.card2 {
  position: relative;
  background: #fff;
  padding: 50px;
  width: 90%;
  margin: 20px auto 0 auto;
  box-shadow: 0 2px 4px rgba(100, 100, 100, .1);
  justify-content: center;
  border-radius: 10px;
}

.icon-white {
  color: rgb(255, 255, 255);
}

.appbar-style {
  background-color: #fff !important;
  color: black;
}

.img-menubar {
  margin: auto;
  width: 180px;
  height: 60px;
}

.card-container {
  width: 300px;
  height: 300px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 0 20px 20px 20px;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: white;
}

.card .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.card .front-content p {
  font-size: 32px;
  font-weight: 700;
  opacity: 1;
  background: rgb(15, 159, 183);
  background: linear-gradient(0deg, rgba(15, 159, 183, 1) 0%, rgba(144, 202, 45, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: rgb(15, 159, 183);
  background: linear-gradient(0deg, rgba(15, 159, 183, 1) 0%, rgba(144, 202, 45, 1) 100%);
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  pointer-events: none;
  transform: translateY(-96%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.card .content .heading {
  font-size: 32px;
  font-weight: 700;
}

.card:hover .content {
  transform: translateY(0);
}

.card:hover .front-content {
  transform: translateY(30%);
}

.card:hover .front-content p {
  opacity: 0;
}

/* Table */

.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
}

thead {
  background-color: #f2f2f2;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  font-weight: bold;
}

tbody tr:hover {
  background-color: #f5f5f5;
}

label {
  margin-right: 10px;
  display: block;
  font-size: 14px;
}

select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.input-form,
.select-form {
  width: 100%;
  min-width: 200px;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #f2f2f2;
}

.fila-form {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.columna-form {
  width: 50%;
  flex-grow: 1;
  display: flex;
}

@media all and (min-width: 35rem) and (max-width: 60rem) {

  .columna-form {
    float: left;
    width: 100%;
  }

  label {
    display: block;
  }
}

@media all and (min-width:60rem) {
  label {
    display: inline;
    min-width: 130px;
    float: left;
    white-space: nowrap;
  }

  .columna-form {
    width: 100%;
    float: left;
  }

  .input-form {
    width: 100%;
  }

  .select-form {
    width: 100%;
  }
}

.checkbox-form {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f2f2f2;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.form-container {
  position: relative;
  border: 1px solid #e1e1e1;
  box-shadow: 0 2px 4px rgba(100, 100, 100, .1);
  border-radius: 8px;
  height: auto;
  width: 100%;
  margin: 30px auto 0 auto;
}

.form-container .title {
  margin: -15px 0 0 10px;
  background: #fff;
  padding: 3px;
  display: inline-block;
  font-weight: bold;
  position: absolute;
}

.form-container .content {
  padding: 20px;
}

.form-container .content-filter {
  padding: 20px;
  width: auto;
  height: 100%;
  display: block;
  flex-wrap: wrap;
}

/* Estilos de botones */

.form-container .button-filter {
  width: 50%;
  flex-grow: 1;
  text-align: left;
}

.form-container .action-button {
  background-color: #213056;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 0 2px;
  cursor: pointer;
  border-radius: 4px;
}

/* Estilos para los botones de acción */
.action-button {
  background-color: #213056;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

/* Cambiar color de fondo cuando se pasa el mouse sobre el botón */
.action-button:hover {
  background-color: #0F9FB7;
}

.button-form {
  width: 50%;
  flex-grow: 1;
  text-align: right;
}

.button-action {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
}

.button-table {
  background-color: #213056;
  color: white;
  border: none;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.button-table:hover {
  background-color: #0F9FB7;
}

/* login cognito */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: system-ui, -apple-system, BlinkMacSystemFont;
  color: #555;
  text-align: center;
  background: -webkit-radial-gradient(center,
      ellipse cover,
      #eee 30%,
      #dddcd7 70%);
  background-color: #eee;
}

.login {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}